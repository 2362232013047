import {
    simpleJsonPost,
} from "../APIProvider";

import {
    API_URL_CREATEKEY,
} from "../../Utils/RemoteConstants";
export {
    createKey,
};
function createKey() {
    return simpleJsonPost(
        API_URL_CREATEKEY,
        null,
        true
    );
}