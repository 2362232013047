import {
    simpleJsonPost,
    simpleJsonGetWithBodyParams,
} from "../APIProvider";

import {
    API_URL_LOGIN,
    API_URL_REGISTER,
    API_URL_GETPROFILE,
    API_URL_FORGOTPASSWORD,
    API_URL_RESETPASSWORD,
    API_URL_CONTACTUS

} from "../../Utils/RemoteConstants";
export {
    loginRemote,
    registerRemote,
    getUserProfile,
    forgotPassword,
    resetPassword,
    contactUs

};
function loginRemote(email, password) {
    return simpleJsonPost(
        API_URL_LOGIN,
        null,
        {
            email: email,
            password: password
        },
        true
    );
}
function registerRemote(first_name,last_name,email,address, password,phone_number) {
    return simpleJsonPost(
        API_URL_REGISTER,
        null,
        {
            first_name:first_name,
            last_name:last_name,
            email:email,
            address: address,
            password: password,
            phone_number:phone_number,
        },
        true
    );
}
function forgotPassword(email) {
    return simpleJsonPost(
        API_URL_FORGOTPASSWORD,
        null,
        {
            email: email,
        },
        true
    );
}

function resetPassword(token,password) {
    return simpleJsonPost(
        API_URL_RESETPASSWORD,
        null,
        {
            token: token,
            password: password,
        },
        true
    );
}
function contactUs(name,email,subject,message) {
    return simpleJsonPost(
        API_URL_CONTACTUS,
        null,
        {
            name:name,
            email: email,
            subject:subject,
            message: message
        },
        true
    );
}
function getUserProfile(username) {
    return simpleJsonGetWithBodyParams(
        API_URL_GETPROFILE,
        null,
        {
            username: username,
        },
        true
    );
}


