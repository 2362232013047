import React, { Component } from "react";
export default class LandingPage extends Component {
  render() {
    return (
      <>
     <div className="row landingPageMargin">
      <div className="row landingPagetitle"><h1>API Dev Portal</h1></div>
      <div className="row">
            <div className="col-sm-12 col-lg-4 col-md-12 api-method api_bg_blue m-r-30">
              <div className="contact-about">
                {/* <h5>About Fintech</h5> */}
                <div className="item">
                  <p className = "method-title">First, make a GET request:</p>
                  <ol className="t-a-j">
                    <li className="t-a-l">Click on <b>Test APIs</b> tab and then expand the <br/> GET
                      <span className="method-url"> /api/getuserdetails/</span>
                    </li>
                    <li>Click <b>Try it out</b> button.</li>
                    <li>Enter information parameter like: user_id =15.</li>
                    <li>Click <b>Execute</b> button to show your results.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-12 api-method api_bg_blue m-r-30">
              <div className="contact-about">
                <div className="item">
                  <p className = "method-title">Now let&rsquo;s make a POST request:</p>
                  <ol className="t-a-j">
                    <li className="t-a-l">Click on <b>Test APIs</b> tab and then expand the <br/> POST
                      <span className="method-url"> /api/create_invoice/endpoint.</span>
                    </li>
                    <li>Click <b>Try it out</b> button.</li>
                    <li>After you click Try it out, the example value in the Request Body field becomes editable.</li>
                    <li>In the Example Value field, change the first purchase_order_number value to a random integer, such as 1200.</li>
                    <li>Click <b>Execute</b> button.</li>
                    <li>Swagger UI submits the request and shows the curl that was submitted. The Responses section shows the response.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 col-md-12 api-method api_bg_blue">
              <div className="contact-about">
                <div className="item">
                  <p className = "method-title">Make a PUT request:</p>
                  <ol className="t-a-j">
                    <li className="t-a-l">Click on <b>Test APIs</b> tab and then expand the <br/> PUT
                      <span className="method-url"> /api/updatecontact/15.</span>
                    </li>
                    <li>Click <b>Try it out</b> button.</li>
                    <li>After you click Try it out, the example value in the Request Body field becomes editable.</li>
                    <li>In the Example Value field, enter a valid address and phone number.</li>
                    <li>Click <b>Execute</b> button.</li>
                    <li>Swagger UI submits the request and shows the curl that was submitted.
                        The Responses section shows the response.</li>
                  </ol>
                </div>
              </div>
            </div>
            </div>
      </div>
     
        {/* <div className="row">
          <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up">Your APIs Simplified</h1>
            <span data-aos="fade-up" data-aos-delay="400">
            Our set of APIs provide an opportunity for you to explore our platform. For using our APIs, you need to register to our platform. After registering, you will be assigned with an access token. All our APIs are secured, you need to provide access token for it. Our UI is very intuitive, you will get a better understanding when you are using it.
            </span>
          </div>
          <div
            className="col-lg-6 order-1 order-lg-2 hero-img"
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <img
              src="./assets/img/hero-img.png"
              className="img-fluid animated"
              alt=""
            />
          </div>
        </div> */}
      </>
    );
  }
}
