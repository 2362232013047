import React, { Component } from "react";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";

export default class NavBarLoggedIn extends Component {
  onLogout() {
    const history = useHistory();
    history.push("/");
  }
  render() {
    return (
      <header
        id="headerloggedin"
        className="fixed-top d-flex align-items-center"
      >
        <div className="container d-flex align-items-center">
          <div className="logo mr-auto">
            <a href="#login">
              {/* <img
                src="./assets/img/cloud-finish-logo-3.png"
                className="img-logo"
              /> */}
            </a>
          </div>

          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li>
                <NavLink to="/loggedin/homepage" className="list-group-item list-group-item-action navbarloggedinnoborder">Home</NavLink>
              </li>
              <li> 
                <NavLink to="/loggedin/profile" className="list-group-item list-group-item-action navbarloggedinnoborder">Profile</NavLink>
              </li>
              {/* <li>
              <NavLink to="/api1" className="dropdown-item">
                API Info
              </NavLink>
              </li> */}
              {/* <li className="drop-down">
                <a>APIs</a>
                <ul>
                  <li>
                    <NavLink to="/api1" className="dropdown-item">
                      Static API
                    </NavLink>
                  </li>
                </ul>
              </li> */}

              <li>
                <a href="/">Logout</a>
              </li>
            </ul>
          </nav>
          {/*  .nav-menu  */}
        </div>
      </header>
    );
  }
}
