import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import { loginRemote } from "../Providers/UserRepository/userRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;
class Login extends Component {l

    constructor(props) {
        super(props)
        this.state = {
          email: '',
          password: '',
          isLoading: false,
          isError:false,
          message:""
        }

    this.onChange = this.onChange.bind(this)
    this.login = this.login.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  redirectDasboard = data => {
    this.props.history.push("/loggedin/homepage");
  };
  login(event) {
    this.setState({
      isLoading:true,
      isError:false
    });

    event.preventDefault();
    event.stopPropagation();
    window.gtag("event", "login", {
      event_category: "access",
      event_label: "login"
  });
    loginRemote(this.state.email,this.state.password).then(result => {
      let code = result.data.code;
      this.setState({
        isLoading:false,
      });
        if (code ==="200") {
          let id = result.data.data.id;
          window.sessionStorage.setItem("id", result.data.data.id);
          window.sessionStorage.setItem("name", result.data.data.name);
          window.sessionStorage.setItem("email", result.data.data.email);
          window.sessionStorage.setItem("address", result.data.data.address);
          this.redirectDasboard(result.data);
        } else if (code ==="206") {
              console.log("Invalid email or password")
              this.setState({
                message : "Invalid email or password",
                isError :true
              })
        }else{
          this.setState({
            isLoading:false,
              message :"Your email id is not yet validated",
              isError :true
          });
        }
    }).catch(err => {
      this.setState({
        isLoading:false,
      });
            console.log(
                "Something went wrong please try again after some time"
            );
        });

  }
        render() {
            return (
                <>
                <div className="section-title">
                  <h2>Login</h2>
                </div>
                <div className={this.state.isLoading?"outer-loader":"d-n"}>
                  <div className="inner-loader">
                    <GridLoader
                        css={override}
                        size={15}
                        margin={4}
                        color={"#0f83ff"}
                        loading={this.state.isLoading}
                    />
                  </div>
                </div>
                <div className="sweet-loading">

                </div>
          
                <div className="row user-action-center" style={{marginTop:"0"}}>
                  <div className="col-lg-4 col-md-6">
                    <div className="contact-about">
                      <h5 className="m-b-20">Welcome</h5>
                      <form className="php-email-form">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                            //autoFocus="true"
                            onChange={this.onChange} required
                          />
                          <div className="validate"></div>
                        </div>
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="password"
                            onChange={this.onChange} required
                            placeholder="Password"
                            data-msg="Invalid password"
                          />
                          <div className="validate"></div>
                        </div>
                        <p hidden={!this.state.isError} style={{color:"red"}}>{this.state.message}</p>
                        <div className="form-group">
                          <div className="text-center">
                          <button type="submit" className="link-button-login" onClick={this.login}
                            style={{background : !this.state.email || !this.state.password 
                              ? 'Gray' : '#3498db'}}
                              disabled={!this.state.email || !this.state.password}>
                              Login
                          </button>
                          </div>
                        </div>
          
                        <div className="text-center">
                        <div className="text-center">
                          <p className="login-link" style={{fontWeight:"bold"}}><Link to="/forgotpassword">Forgot your password?</Link></p>
                        </div>
                        </div>
                        <div className="text-center">
                          <p className="login-link"><Link to="/signup">Sign Up</Link></p>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 mt-4 mt-md-0"></div>
        
                  <div><ToastContainer hideProgressBar={true} position="bottom-center" /></div>
                </div>
              </>
            );
          }
        }
        export default withRouter(Login);
