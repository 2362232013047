import React from "react";
import ContactUs from "./ContactUs";
import APIs from "./APIs";
import Home from "./Home";
import User from "./User";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  BrowserRouter as Router,
  Route,
  HashRouter,
  withRouter,
} from "react-router-dom";
import UserActions from "../components/UserActions/UserActions";
function BeforeLogin() {
  return (
    <>
      <HashRouter>
        <Header />
        <main id="main">
          <Home />
         {/* <APIs /> */}
          <ContactUs />
          <UserActions/>
          {/*<User />*/}
        </main>
      </HashRouter>
      <Footer />
    </>
  );
}

export default withRouter(BeforeLogin);
