import axios from "axios";

// const BASE_URL = "http://192.168.1.174/api";
// const BASE_URL = "http://3.129.172.173/api";
//const BASE_URL = "https://api.cloudstruck.io/api";
const BASE_URL = "https://api.cloudfinish.io/api";
const IS_DEBUG = false;

const onResponseSuccess = response => response;
const onResponseError = err => {
    console.log(err)
    const status = err.status || err.response.status;
    if (status === 500) {
        console.log("error 500 session out");
        // clearArbitraryObject(SESSION_USER_PREFERENCE);
        // localStorage.clear();
        // sessionStorage.clear();
        window.location.replace("/");
        console.log(err.response.data, true);

    }

    return Promise.reject(err);
}
axios.interceptors.response.use(onResponseSuccess, onResponseError);
axios.defaults.timeout = 1000 * 60 * 60;


function simpleJsonGet(url, queryParams, crosProxy = false) {
    axios.defaults.Credentials = true;
    return axios.get(prepenBaseUrl(url, crosProxy), {
        params: queryParams
    }, { Credentials: true });
}
function simpleJsonGetWithBodyParams(url,body = {},params = {}, crosProxy = false) {
    try {
        axios.defaults.Credentials = true;
        const headerOption = {
            "headers": {
                "Content-Type": "application/json"
            }
        };
        return axios.get(prepenBaseUrl(url, crosProxy), body, {
            params: params,
            headerOption
        }, { Credentials: true });
    } catch (error) {
        return interceptError(error)
    }
}

function simpleJsonPost(url, params = {}, body = {}, crosProxy = false) {
    try {
        axios.defaults.Credentials = true;
        const headerOption = {
            "headers": {
                "Content-Type": "application/json"
            }
        };
        return axios.post(prepenBaseUrl(url, crosProxy), body, {
            params: params,
            headerOption
        }, { Credentials: true });
    } catch (error) {
        return interceptError(error)
    }

}


function interceptError(error) {
    return error;
}

function prepenBaseUrl(url, crosProxy = false) {
    return IS_DEBUG && crosProxy ? `https://cors-anywhere.herokuapp.com/${BASE_URL}/${url}` : `${BASE_URL}/${url}`;
}

export {
    simpleJsonGet,
    simpleJsonGetWithBodyParams,
    simpleJsonPost,
    BASE_URL
};
