import React, { Component } from 'react';
import {withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { registerRemote } from "../Providers/UserRepository/userRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import "./styles/signup.css";

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;
class SignUp extends Component {
    constructor(props) {
      super(props)
      this.state = {
        firstname: "",
        lastname: "",
        password: "",
        email: "",
        address:"",
        address2:"",
        phonenumber:"",
        confirmpassword: "",
        isLoading: false,
        validEmail :true,
        validPhone :true,
        passwordmatch:true,
        validPassword:true,
        validAddress:true,
        emailError:true,
        phoneError:true,
        addressMessage:"",
        passwordMessage:"",
        phoneMessage:"",
        emailMessage:""
      }

  this.onChange = this.onChange.bind(this)
  this.signUp = this.signUp.bind(this);
  }

  redirectLogin = data => {
    this.props.history.push("/login");
  };
  onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
  }
  checkSpecialCharacters(e){
    var regex = new RegExp("^[a-zA-Z ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
  }
  validate(emailId){
    if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(emailId)) { 
      this.setState({
        emailError:true,
      });
      return true;
    }else{
      this.setState({
        emailError:false,
      });
      return false;
    }
  }
  validatePhone(contactnumber){
    let isValid = true;
    this.setState({
      validPhone:true,
    });
    if(contactnumber.length > 0){
          let phone = "+"+contactnumber;
          var pattern = new RegExp(/^\+[1-9]{1}[0-9]{3,14}$/);
          if (!pattern.test(phone)) {
          isValid = false;
          this.setState({
            phoneError:false,
            phonenumber:phone
          });
        }else{
          isValid = true;
          this.setState({
            phoneError:true,
          });
        }
      }
    return (isValid)
  }

  signUp(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
        validAddress:true,
        validEmail:true,
        validPassword:true,
        validPhone:true,
        emailError:true,
        phoneError:true,
        addressMessage:"",
        passwordMessage:"",
        phoneMessage:"",
        emailMessage:""
    })
    let passwordErr = "";
    let emailErr = "";
    let addressErr = "";
    let phoneErr = "";
    let phone_number = "";
    if(this.state.password === this.state.confirmpassword){
      this.setState({
        passwordmatch:true,
      });
       if(this.validate(this.state.email)){
        if(this.validatePhone(this.state.phonenumber)){
          this.setState({
            isLoading:true,
          });
          if(this.state.phonenumber.length > 0){
            phone_number = "+"+this.state.phonenumber;
          }else{
            phone_number = this.state.phonenumber;
          }
          registerRemote(this.state.firstname,this.state.lastname,this.state.email,
            this.state.address+this.state.address2,this.state.password,phone_number).then(result => {
              let code = result.data.code;
              this.setState({
                isLoading:false,
              });
              if (code === 200) {
                  this.redirectLogin(result.data);
                  toast.info("Registration completed successfully,Please verify your registered email id to continue")
              }else if (code === 206) {
                let error = result.data.error;
                if ('password' in error){
                    passwordErr = error.password[0];
                    this.setState({
                      validPassword:false,
                      passwordMessage:passwordErr
                    });
                }
                if ('phone_number' in error){
                   phoneErr = error.phone_number[0];
                   this.setState({
                    validPhone:false,
                    phoneMessage:phoneErr
                  });
                }
                if ('email' in error){
                   emailErr = error.email[0];
                   this.setState({
                    validEmail:false,
                    emailMessage:emailErr
                  });
                }
                if ('address' in error){
                  addressErr = error.address[0];
                  this.setState({
                    validAddress:false,
                    addressMessage:addressErr
                  });
                }
            }else{
              this.setState({
                validAddress:true,
                validEmail:true,
                validPassword:true,
                validPhone:true,
                emailError:true,
                phoneError:true,
                addressMessage:"",
                passwordMessage:"",
                phoneMessage:"",
                emailMessage:""
              });
                console.log("Something went wrong,Please try again later")
                toast.info("Something went wrong,Please try again later")
              }
          }).catch(err=>console.log(err))
          }else{
            console.log("Please enter a valid phone number")
          }
          }else{
            console.log("Please enter a valid email id")
          }
    }else{
        this.setState({
          passwordmatch:false,
        });
    }
}
  render() {
    return (
      <section id="signup" style={{"paddingTop": 10, "marginTop": 2}}>
        <div className="section-title ">
          <h2>Sign Up</h2>
        </div>

        <div className={this.state.isLoading?"outer-loader":"d-n"}>
          <div className="inner-loader">
            <GridLoader
                css={override}
                size={15}
                margin={4}
                color={"#0f83ff"}
                loading={this.state.isLoading}
            />
          </div>
        </div>

        <div className="sweet-loading">
      </div>

        <div className="row user-action-center">
          <div className="col-lg-8 col-md-8">
            <div className="contact-about">
              {/* <h5>Welcome</h5> */}
              <div className="php-email-form">
                <div className="flex-split">
                  <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        name="firstname"
                        id="firstname"
                        autoFocus={true}
                        onKeyPress={this.checkSpecialCharacters}
                        onChange={this.onChange} required
                        placeholder="First Name *"
                        data-msg=""
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        onKeyPress={this.checkSpecialCharacters}
                        onChange={this.onChange} required
                        id="lastname"
                        placeholder="Last Name *"
                        data-msg=""
                    />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="flex-split">
                  <div className="form-group">
                    <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={this.onChange} required
                        id="email"
                        placeholder="Email *"
                        style={{borderColor: !this.state.validEmail || this.state.emailError ? '':'red'}}
                        data-msg="Please enter a valid email"
                    />
                    <p hidden={this.state.emailError} style={{color :"red"}}>Please enter a valid email id</p>
                    <p hidden={this.state.validEmail} style={{color :"red"}}>{this.state.emailMessage}</p>
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <PhoneInput
                        country={'us'}
                        placeholder="Phone number"
                        id="phonenumber"
                        name="phonenumber"
                        value={this.state.phonenumber}
                        onChange={phonenumber => this.setState({ phonenumber })} required
                        style={{borderColor: this.state.validPhone || !this.state.phoneErr ? '':'red'}}
                        data-msg="Please enter a valid contact number"
                    />
                    <p hidden={this.state.validPhone} style={{color :"red"}}>{this.state.phoneMessage}</p>
                    <p hidden={this.state.phoneError} style={{color :"red"}}>Phone number should be starting with country code</p>
                    <div className="validate"></div>
                  </div>
                </div>

                <div className="flex-split">
                  <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        name="address"
                        onChange={this.onChange} required
                        id="address"
                        maxLength="100"
                        placeholder="Address Line 1 *"
                        data-msg="Please enter a valid address"
                        style={{borderColor:this.state.validAddress ? '':'red'}}
                    />
                    <p hidden={this.state.validAddress} style={{color :"red"}}>{this.state.addressMessage}</p>
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        name="address2"
                        onChange={this.onChange}
                        id="address2"
                        maxLength="100"
                        placeholder="Address Line 2"
                        data-msg="Please enter a valid address"
                    />
                    <p hidden={this.state.validAddress} style={{color :"red"}}>{this.state.addressMessage}</p>
                    <div className="validate"></div>
                  </div>
                </div>

                <div className="flex-split">
                  <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={this.onChange} required
                        style={{borderColor:this.state.passwordmatch || !this.state.validPassword ? '':'red'}}
                        id="password"
                        placeholder="Password *"
                        data-msg="Invalid password"
                    />
                    <p hidden={this.state.validPassword} style={{color :"red"}}>{this.state.passwordMessage}</p>
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        style={{borderColor:this.state.passwordmatch || !this.state.validPassword  ? '':'red'}}
                        onChange={this.onChange} required
                        id="password"
                        placeholder="Confirm Password *"
                        data-msg="Invalid password"
                    />
                    <p hidden={this.state.passwordmatch} style={{color :"red"}}>Password mismatch</p>
                    <div className="validate"></div>
                  </div>
                </div>

                <div className="text-center">
                <button type="submit" className="signup-button" onClick={this.signUp}
                  style={{background : !this.state.firstname || !this.state.lastname ||
                    !this.state.email || !this.state.password ||!this.state.confirmpassword 
                    ? 'Gray' : '#3498db'}}
                  disabled={!this.state.firstname || !this.state.lastname ||
                    !this.state.email || !this.state.password ||!this.state.confirmpassword }>
                  Sign Up
                </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4 mt-md-0"></div>
        <div><ToastContainer hideProgressBar={true} position="bottom-center" /></div>
        </div>
      </section>
    );
  }
}
export default withRouter(SignUp);
