import React, { Component } from "react";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import NavBarLoggedIn from "../components/NavBarLoggedIn";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  HashRouter,
} from "react-router-dom";
import Profile from "./Profile";
import GenerateToken from "./GenerateToken";
import LandingPage from "./LandingPage";
import SwaggerAPI from "./SwaggerAPI";
import API1 from "./OtherAPIs/API1";
class AfterLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
    };
  }
  toggleSidebar(e) {
    this.setState({
      isActive: !this.state.isActive,
    });
  }
  render() {
    return (
      <>
        <div>
          <div
            className={this.state.isActive ? "d-flex toggled" : "d-flex"}
            id="wrapper"
          >
            <Sidebar />
            <div id="page-content-wrapper">
              <div className="d-flex navbar-light border-bottom align-items-center">
                <Link to="#" id="menu-toggle">
                  <FaIcons.FaBars onClick={() => this.toggleSidebar(0)} />
                </Link>
                <NavBarLoggedIn />
              </div>
              <div className="container-fluid">
                <Switch>
                  <Route exact path={"/loggedin/profile"}  component={Profile}></Route>
                  <Route
                    exact path={"/loggedin/generatetoken"}
                    component={GenerateToken}
                  ></Route>
                  <Route exact path={"/loggedin/homepage"} component={LandingPage}></Route>
                  <Route exact path={"/loggedin/api1"} component={API1}></Route>
                  <Route
                    exact path={"/loggedin/fintechapi"}
                    component={SwaggerAPI}
                  ></Route>
                </Switch>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(AfterLogin);
