import React, { Component } from "react";
import "../components/styles/footer.css";

export default class Footer extends Component {
  render() {
    return (
      <footer id="footer" style={{width:"100%"}}>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 text-lg-left text-center">
              <div className="copyright">
                &copy; Copyright <strong>API Dev Portal</strong>. All Rights
                Reserved
              </div>
              {/*<div className="credits"></div>*/}
            </div>
            <div className="col-lg-6">
              <nav className="footer-links text-lg-right text-center pt-2 pt-lg-0">
                {/* <a href="#home" className="scrollto">
                  Home
                </a>
                <a href="#contact" className="scrollto">
                  Let’s Meet
                </a> */}
                <a href="#">Privacy Policy</a>
                <a href="#">Terms of Use</a>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
