import React, { Component } from 'react';
import { withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from "../Providers/UserRepository/userRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;
class ResetPassword extends Component {
    constructor(props) {
      super(props)
      this.state = {
        email: "",
        token:"",
        isLoading: false,
      }

  this.onChange = this.onChange.bind(this)
  this.ResetPassword = this.ResetPassword.bind(this);
  }
  componentDidMount(){
    let url =window.location.href;
    const res = url.split('?', 2);
    const token = res[1].split('=', 2);
    this.setState({
      token:token[1],
    });
  }
  redirectLogin = data => {
    this.props.history.push("/login");
  };
  onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
  }

  ResetPassword(event) {
    this.setState({
      isLoading:true,
    });
    event.preventDefault();
    event.stopPropagation();
       if(this.state.password === this.state.confirmpassword){
        resetPassword(this.state.token,this.state.password).then(result => {
          let code = result.data.code;
          this.setState({
            isLoading:false,
          });
            if (code === 201) {
              this.redirectLogin(result.data);
              toast.info("Password reset successfully")
            }else if (code === 400) {
              toast.info("Password length should be minimum 8 and don't use common password")
            } else if (code === 404){
              console.log("Invalid token")
              toast.info("Invalid token")
            }else{
              console.log("Something went wrong,Pls try later")
              toast.info("Something went wrong,Pls try later")
            }
        }).catch(err => {
          this.setState({
            isLoading:false,
          });
                console.log(
                    "Something went wrong please try again after some time"
                );
            });
        }else{
            console.log("Password mismatch")
            toast.info("Password mismatch")
        }
    }
  
  render() {
    return (
      <div class="card" 
      style={{"width": "50%","border": "2px solid gray","marginLeft":"25%","marginTop":"5%"}}>     
    <section id="signup" >
        <div className="section-title">
          <h2>Reset Password?</h2>
        </div>
        <div className="sweet-loading">
            <GridLoader
              css={override}
              size={18}
              margin={4}
              color={"#0f83ff"}
              loading={this.state.isLoading}
            />        
          </div>
        <div className="text-center">
        <div className ="reset-div">
          <div className="col-md-8">
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    onChange={this.onChange} required
                    placeholder="New password"
                    data-msg=""
                  />
                  </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmpassword"
                    id="confirmpassword"
                    onChange={this.onChange} required
                    placeholder="Confirm password"
                    data-msg=""
                  />
                </div>
                <p style={{color :"red",fontSize:"11px",fontWeight:"bold"}}>Password should contain alphabets,numbers and special characters <br/> 
                and length should be minimum of 8 </p>
                <div className="text-center">
                <button type="submit" className="signup-button" onClick={this.ResetPassword}
                    disabled={!this.state.password || !this.state.confirmpassword}>Reset</button>
                </div>
          </div>
        <div><ToastContainer hideProgressBar={true} position="bottom-center" /></div>
        </div>
        </div>
      </section>
      </div> 
    );
  }
}
export default withRouter(ResetPassword);
