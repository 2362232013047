import React, { Component } from 'react';
import { withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { forgotPassword } from "../Providers/UserRepository/userRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;
class ForgotPassword extends Component {
    constructor(props) {
      super(props)
      this.state = {
        email: "",
        color:"",
        isLoading: false,
        isError:false,
      }

  this.onChange = this.onChange.bind(this)
  this.ForgotPassword = this.ForgotPassword.bind(this);
  }

  redirectLogin = data => {
    this.props.history.push("/login");
  };
  onChange(e) {
  this.setState({ [e.target.name]: e.target.value });
  }
  validate(emailId){
    if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(emailId)) { 
      this.setState({
        color:"",
      });
      return true;
    }else{
      this.setState({
        color:"red",
      });
      return false;
    }
  }
  ForgotPassword(event) {
    this.setState({
      isLoading:true,
      isError:false
    });
    event.preventDefault();
    event.stopPropagation();
       if(this.validate(this.state.email)){
        forgotPassword(this.state.email).then(result => {
          let status = result.data.code;
          this.setState({
            isLoading:false,
          });
            if (status ===201) {
              this.redirectLogin(result.data);
              toast.info("Reset password link has been sent to your registered mail id")
            } else{
              toast.info("There is no active user associated with this e-mail address or the password can not be changed")
            }
        }).catch(err => {
          this.setState({
            isError:true,
            isLoading:false,
          });
            console.log("Something went wrong please try again after some time");
            });
        }else{
            console.log("Please enter a valid email id")
            toast.info("Please enter a valid email id")
        }
    }
  render() {
    return (
      <section id="signup" style={{"paddingTop": 10, "marginTop": 2}}>
        <div className="section-title ">
          <h2>Forgot Your Password?</h2>
        </div>

        <div className={this.state.isLoading?"outer-loader":"d-n"}>
          <div className="inner-loader">
            <GridLoader
                css={override}
                size={15}
                margin={4}
                color={"#0f83ff"}
                loading={this.state.isLoading}
            />
          </div>
        </div>

        <div className="sweet-loading">
          </div>

        <div className="row user-action-center">
          <div className="col-lg-4 col-md-6">
            <div className="contact-about">
              {/* <h5>Welcome</h5> */}
              <div className="php-email-form">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    autoFocus="true"
                    style={{borderColor:this.state.color}}
                    onChange={this.onChange} required
                    placeholder="Enter your registered email id!"
                    data-msg=""
                  />
                  <p hidden={!this.state.isError} style={{color :"red"}}>There is no active user associated with this e-mail address,or the password can not be changed</p>
                  <div className="validate"></div>
                </div>
                <div className="text-center">
                  <button type="submit" className="signup-button" onClick={this.ForgotPassword}
                  style={{background : !this.state.email ? 'Gray' : '#3498db'}}
                  disabled={!this.state.email }>Reset Password</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-4 mt-md-0"></div>
        <div><ToastContainer hideProgressBar={true} position="bottom-center" /></div>
        </div>
      </section>
    );
  }
}
export default withRouter(ForgotPassword);
