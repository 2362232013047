import React, { Component } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import { loginRemote } from "../Providers/UserRepository/userRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';
import { contactUs } from "../Providers/UserRepository/userRepository";
import "./styles/contactus.css"

const override = css`
  display: block;
  margin: 0 auto;
`;

export default class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name:'',
      email: '',
      subject:'',
      message:'',
      isLoading: false,
      isError:false,
    }

this.onChange = this.onChange.bind(this)
this.sendMessage = this.sendMessage.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}

checkSpecialCharacters(e){
  var regex = new RegExp("^[a-zA-Z ]+$");
  var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
      return true;
  }

  e.preventDefault();
  return false;
}

validate(emailId){
  if (/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(emailId)) { 
    this.setState({
      isError:false,
    });
    return true;
  }else{
    this.setState({
      isError:true,
    });
    return false;
  }
}

sendMessage(event) {
  event.preventDefault();
  event.stopPropagation();
  if(this.validate(this.state.email)){
    this.setState({
      isLoading:true,
      isError:false
    });
  contactUs(this.state.name,this.state.email,this.state.subject,this.state.message).then(result => {
    let code = result.data.code;
    this.setState({
      isLoading:false,
    });
      if (code ===200) {
        let message = result.data.message;
        this.setState({
          name:'',
          email:'',
          subject:'',
          message:''
        });
        toast.info(message)
      }else{
        this.setState({
          isLoading:false,
          isError :true
        });
      }
  }).catch(err => {
    this.setState({
      isLoading:false,
    });
          console.log(
              "Something went wrong please try again after some time"
          );
      });
    }else{
        console.log("Please enter a valid email id")
  }

}

  render() {
    return (
      <section id="contact" className="contact">
        
      <div className="container">
        <div className="section-title">
          <h2 className="apititle">Our APIs</h2>
        </div>

      <div className="sweet-loading">
        <GridLoader
          css={override}
          size={18}
          margin={4}
          color={"#0f83ff"}
          loading={this.state.isLoading}
        />
      </div>

        <div className="about-wrapper">
          <div className="about-us">
            <div className="row">
            <div className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Get User Details</h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/getuserdetails/</span></div>
            <div className="row linespace"><span>Shows User basic profile details, address and contact number which is generated by userId</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Update contact</h5></div>
            <div className="row linespace new-line">PUT<span className="apiurl"> /api/updatecontact/15</span></div>
            <div className="row linespace"><span>You can update the address and contact number by userId</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Get Recent Transactions </h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/getrecent_transactions/</span></div>
            <div className="row linespace"><span>Shows recent 20 transactions of user by user id and card number</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Create Invoice </h5></div>
            <div className="row linespace new-line">POST<span className="apiurl"> /api/create_invoice/</span></div>
            <div className="row linespace"><span>Invoice creation with purchase order</span></div>
            </div>
            </div>
            <div className="row apimargin">
            <div  className="col-sm-12 col-lg-3 col-md-6  api-method maxwidth">
            <div className="row linespace"><h5>Get Transaction Details</h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/get_transaction_details/</span></div>
            <div className="row linespace"><span>Shows transactions of user by user id, card number and transaction number</span></div>
            </div>
            
            <div className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Update Credit Limit</h5></div>
            <div className="row linespace new-line">POST<span className="apiurl"> /api/getuserdetails/</span></div>
            <div className="row linespace"><span>You can send the service request by user Id and card number</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6  api-method maxwidth">
            <div className="row linespace"><h5>Get Customer Invoice</h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/updatecontact/15</span></div>
            <div className="row linespace"><span>Fetch customer invoice details using customer id</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6  api-method maxwidth">
            <div className="row linespace"><h5>Get Vendor Invoice </h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/getrecent_transactions/</span></div>
            <div className="row linespace"><span>Fetch vendor invoice details using vendor id</span></div>
            </div>
            </div>
            <div className="row apimargin">
            <div  className="col-sm-12 col-lg-3 col-md-6 no-border none-display api-method maxwidth">
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6 api-method maxwidth">
            <div className="row linespace"><h5>Get Invoice Details </h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/create_invoice/</span></div>
            <div className="row linespace"><span>Fetch invoice details using invoice id</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6  api-method maxwidth">
            <div className="row linespace"><h5>Get Total Amount</h5></div>
            <div className="row linespace new-line">GET<span className="apiurl"> /api/get_transaction_details/</span></div>
            <div className="row linespace"><span>Fetch total amount per invoice using invoice_id</span></div>
            </div>
            <div  className="col-sm-12 col-lg-3 col-md-6 no-border none-display api-method maxwidth">
            </div>
            </div>
            {/* <ul>
              <li>Platform for documenting and Testing APIs.</li>
              <li>For using our platform, one needs to Sign up.</li>
              <li>After Sign up, token needs to be generated using Generate Token option.</li>
              <li>Total of 10 APIs are provided. All the APIs are secured from external access.</li>
              <li>Instead of providing access token every time for testing API, one can use the Authorization option. It will capture the access token and send it along with each API request.</li>
              <li>After the authorization step, we are now ready to test the API. Let&rsquo;s do an example:</li>
            </ul> */}
            <div className="row justify-text">
            <a className="apisignup" href="#signup">Start testing once you Sign Up</a>
            </div>
          </div>
          {
            /*
          <div className="row">
          <div className="col-lg-4 col-md-6 api-method m-r-30">
            <div className="contact-about">
              <div className="item">
                <p className = "method-title">First, make a GET request:</p>
                <ol className="t-a-j">
                  <li className="t-a-l">Expand the <br/> GET
                    <span className="method-url"> /api/getuserdetails/</span>
                  </li>
                  <li>Click Try it out</li>
                  <li>Enter information parameter like: user_id =15</li>
                  <li>Click Execute button to show your results</li>
                </ol>
              </div>
              
              {/* <div className="social-links">
                <a href="#" className="twitter">
                  <i className="icofont-twitter"></i>
                </a>
                <a href="#" className="facebook">
                  <i className="icofont-facebook"></i>
                </a>
                <a href="#" className="instagram">
                  <i className="icofont-instagram"></i>
                </a>
                <a href="#" className="linkedin">
                  <i className="icofont-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 api-method m-r-30">
            <div className="contact-about">
              <div className="item">
                <p className = "method-title">Now let&rsquo;s make a POST request:</p>
                <ol className="t-a-j">
                  <li className="t-a-l">Expand the <br/> POST
                    <span className="method-url"> /api/create_invoice/endpoint.</span>
                  </li>
                  <li>Click Try it out.</li>
                  <li>After you click Try it out, the example value in the Request Body field becomes editable.</li>
                  <li>In the Example Value field, change the first purchase_order_number value to a random integer, such as 1200.</li>
                  <li>Click Execute.</li>
                  <li>Swagger UI submits the request and shows the curl that was submitted. The Responses section shows the response.</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 api-method">
            <div className="contact-about">
              <div className="item">
                <p className = "method-title">Make a PUT request:</p>
                <ol className="t-a-j">
                  <li className="t-a-l">Expand the <br/> PUT
                    <span className="method-url"> /api/updatecontact/15.</span>
                  </li>
                  <li>Click Try it out.</li>
                  <li>After you click Try it out, the example value in the Request Body field becomes editable.</li>
                  <li>In the Example Value field, enter a valid address and phone number.</li>
                  <li>Click Execute.</li>
                  <li>Swagger UI submits the request and shows the curl that was submitted.
                      The Responses section shows the response.</li>
                </ol>
              </div>
            </div>
          </div>
          </div>
        */}
          
          {/* <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
            <div className="info">
              <div>
                <i className="ri-map-pin-line"></i>
                <p>A108 Abc Street New York NY 535022</p>
              </div>

              <div>
                <i className="ri-mail-send-line"></i>
                <p>info@cloudfinish.io</p>
              </div>

              <div>
                <i className="ri-phone-line"></i>
                <p>123 456 7890</p>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-5 col-md-12">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              className="php-email-form"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  value={this.state.name}
                  onKeyPress={this.checkSpecialCharacters}
                  placeholder="Your Name"
                  data-rule="minlen:4"
                  onChange={this.onChange} 
                  data-msg="Please enter at least 4 chars"
                />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  value={this.state.email}
                  placeholder="Your Email"
                  data-rule="email"
                  onChange={this.onChange} 
                  data-msg="Please enter a valid email"
                />
                <p hidden={!this.state.isError} style={{color:"red"}}>Enter a valid email id</p>
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  value={this.state.subject}
                  placeholder="Subject"
                  data-rule="minlen:4"
                  onChange={this.onChange} 
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  value={this.state.message}
                  id="message"
                  rows="5"
                  maxLength="500"
                  data-rule="required"
                  data-msg="Please write something for us"
                  onChange={this.onChange} 
                  placeholder="Message"
                ></textarea>
                <div className="validate"></div>
              </div>
              <div className="text-center">
              <button type="submit" className="link-button-login" onClick={this.sendMessage}
                 style={{background : !this.state.name || !this.state.email ||
                  !this.state.subject || !this.state.message  
                  ? 'Gray' : '#3498db'}}
                disabled={!this.state.name || !this.state.email ||
                  !this.state.subject || !this.state.message }>
                  Send Message
              </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
    </section>
    );
  }
}
