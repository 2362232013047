import React, { Component } from "react";
import User from "../../Pages/User"
import "./user_actions.css";

export default class UserActions extends Component {
    render() {
        return (
            <div className="container user-wrapper">
                <div className="user-container">
                    <h3>Start Exploring API Dev Portal </h3>
                    {/*<button className="login-button"> Start Exploring </button>*/}
                </div>
                <User/>
            </div>
        );
    }
}
