import React, { Component } from "react";
import { Link } from "react-router-dom";
import  "../components/styles/header.css";
import HeaderMobile from "./HeaderMobile/HeaderMobile";
export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isHome:true,
      isApi:false,
      isLogin:false,
      isSignup:false,
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    //const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const title = document.querySelector('#login');
    const home = document.querySelector('#main');
    const api = document.querySelector('.apititle');
    const signup = document.querySelector('#signup');
    
    const titleTop = title.offsetTop;
    const homeTop = home.offsetTop;
    const apiTop = api.offsetTop;
    var signupTop = false;
    if(signup)
    {
      signupTop = signup.offsetTop;
    }
    
    

    
  
 if(winScroll < homeTop)
 {
  this.setState({
    isHome:true,
    isApi:false,
    isLogin:false,
    isSignup:false,
  });
 }
  if(winScroll >= homeTop && winScroll < apiTop)
  {
   
    this.setState({
      isHome:false,
      isApi:true,
      isLogin:false,
      isSignup:false,
    });
  }

  if(winScroll >= apiTop && winScroll < titleTop)
  {
   
    this.setState({
      isHome:false,
      isApi:false,
      isLogin:true,
      isSignup:false,
    });
  }

  if(signupTop && winScroll >= titleTop && winScroll < signupTop)
  {
   
    this.setState({
      isHome:false,
      isApi:false,
      isLogin:false,
      isSignup:true,
    });
  }
  
    
  }
  
  render() {
    return (
        <>
          <header id="header" className="fixed-top d-flex align-items-center" style={{position:"absolute !important "}}>
            <div className="container d-flex align-items-center">
              <div className="logo mr-auto">
                <a href="/">
                  <img
                    src="./assets/img/fintechlogo.png"
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </div>

              <nav className="nav-menu d-none d-lg-block">
                <ul>
                  <li className={this.state.isHome ? "active" : "inactive"}>
                    <a href="#hero">Home</a>
                  </li>
                  <li className={this.state.isApi ? "active" : "inactive"} >
                    <a href="#contact">Our APIs</a>
                  </li>
                  <li className={this.state.isLogin ? "active" : "inactive"}>
                    <a  href="#login">Login</a>
                     {/* <Link to="/login">Login</Link>   */}
                  </li>
                  <li className={this.state.isSignup ? "active" : "inactive"}>
                  <a className="signup-header-button" href="#signup">Sign Up</a>
                  </li>
                </ul>
              </nav>
              {/*  .nav-menu className="signup-header-button" */}
            </div>
          </header>
          <HeaderMobile/>
        </>
    );
  }
}
