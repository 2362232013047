import React, { Component } from 'react';
import {withRouter } from "react-router";
import MenuIcon from '../../components/img/menu.png'
import './HeaderMobile.css';


class HeaderMobile extends Component {

    constructor() {
        super();
        this.state = {
            closeClass:"",
            minimizeClass:""
        };
    }

    handleClick=(e)=>{
        this.setState({
            closeClass:"",
            minimizeClass:"",
        })
    }

    handleToggle=(e)=>{
        this.setState({
            closeClass:"remove_bg",
            minimizeClass:"remove"
        })
    }

    render() {
        return (
            <div className="container-wrapper">
                <span className="top-bar">
                    <img
                        className ="menu-button"
                        alt= "menu-button"
                        src={MenuIcon}
                        onClick={this.handleToggle}
                    />
                     <img className="logo-main" src="./assets/img/fintechlogo.png" alt="fintech logo" />
                </span>
                <div onClick={this.handleClick} className={ "m-header-wrapper "+ this.state.closeClass}>
                    <div className={"container "+ this.state.minimizeClass}>
                        <div>
                            <img className="logo" src="./assets/img/fintechlogo.png" alt="fintech logo" />
                        </div>
                        <nav className="nav-menu">
                            <ul>
                                <li className="active">
                                    <a onClick={this.handleClick}
                                       href="#hero">Home</a>
                                </li>
                                <li>
                                    <a onClick={this.handleClick}
                                       href="#contact">Our APIs</a>
                                </li>
                                <li>
                                    <a onClick={this.handleClick}
                                       className="" href="#login">Login</a>
                                    {/* <Link to="/login">Login</Link>   */}
                                </li>
                                <li>
                                    <a onClick={this.handleClick}
                                       className="" href="#signup">Sign Up</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(HeaderMobile);
