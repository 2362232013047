import React from "react";
import "./App.css";
import AfterLogin from "./Pages/AfterLogin";
import BeforeLogin from "./Pages/BeforeLogin";
import ResetPassword from "./Pages/ResetPassword"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  withRouter,
} from "react-router-dom";

function App() {
  return (
    <>
      <HashRouter>
        <Switch>
          <Route path="/" exact component={BeforeLogin} />
          <Route path="/loggedin" component={AfterLogin} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="*" component={BeforeLogin} />
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
