import React, { Component } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";
import { createKey } from "../Providers/TokenRepository/tokenRepository";
import { css } from "@emotion/core";
import { GridLoader } from 'react-spinners';
import "./styles/generate_token.css";

const override = css`
  display: block;
  margin: 0 auto;
  line-height: 0;
`;

class GenerateToken extends Component {
  constructor(props) {
    super();
    {
      this.state = {
        generatedToken: "",
        hidekeybuttonvisible: false,
        tokenvisible: true,
        isLoading: false,
        buttonName: "show"
      };
    }
  }
  componentDidMount() {
    this.handleViewClick();
  }
  handleViewClick = () => {
    this.setState({
      isLoading:true,
    });
    createKey().then(result => {
      this.setState({
        isLoading:false,
      });
      let code = result.data.code;
        if (code ==="010") {
            this.setState({
            generatedToken: result.data.data.api_key, //response.data.data.name,
            hidekeybuttonvisible: true,
            tokenvisible: true,
          });
        }else{
          console.log("Something went wrong,Pls try later!")
          toast.info("Something went wrong,Pls try later!")
        }
    }).catch(err => {
      this.setState({
        isLoading:false,
      });
            console.log(
                "Something went wrong please try again after some time"
            );
        });
  }
  handleResetClick = () => {
    this.setState({
      isLoading:true,
    });
     createKey().then(result => {
      this.setState({
        isLoading:false,
      });
      let code = result.data.code;
      console.log("code--->" + code);
        if (code ==="010") {
            this.setState({
              generatedToken: result.data.data.api_key, //response.data.data.name,
              hidekeybuttonvisible: true,
              tokenvisible: true,
          });
        }else{
          console.log("Something went wrong,Pls try later!")
          toast.info("Something went wrong,Pls try later!")
        }
    }).catch(err => {
      this.setState({
        isLoading:false,
      });
            console.log(
                "Something went wrong please try again after some time"
            );
        });
  };

  handleShowTokenVisibleViewClick =() =>{
    this.setState({
      hidekeybuttonvisible: true,
      tokenvisible: true,
    });
  }
  handleHidetokenvisibleViewClick = () => {
    this.setState({
      tokenvisible: false,
      hidekeybuttonvisible: false,
    });
  };

  changeButtonState=()=>{
      if(this.state.buttonName === "show"){
          this.setState({
              buttonName:"hide"
          })
      }
      else if(this.state.buttonName === "hide"){
          this.setState({
              buttonName:"show"
          })
      }
    }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-lg-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 className="g-t-m-t">Generate Token</h1>
            <div className={this.state.isLoading?"outer-loader":"d-n"}>
                <div className="inner-loader">
                    <GridLoader
                          css={override}
                          size={15}
                          margin={4}
                          color={"#0f83ff"}
                          loading={this.state.isLoading}
                    />
                </div>
            </div>
            <div className="api-key-wrapper">
                <div className="api-key-inner">
                    <p className="a-k-t"> Access Token: </p>
                    <p className="show-element key">
                        {this.state.buttonName!=="show" ? this.state.generatedToken : "Api-Key ..."}
                    </p>
                </div>


                <div className="button-wrapper">
                    <button className="link-button"
                            onClick={this.changeButtonState}>
                        {this.state.buttonName}
                    </button>
                    <button className="link-button"
                            onClick={this.handleResetClick}>
                        Reset
                    </button>
                </div>
            </div>

            {
              /*
            <div className="row" style={{marginTop:"2%"}}>
            <div className="col-md-3" style={{paddingLeft:"6%",fontWeight:"bold"}}>
              <div className="contact-about">
                <div className="form-group">
                  <label
                    className={
                      this.state.tokenvisible ? "show-element" : "hide-element"
                    }>Access Token:</label>
                </div>
              </div>
            </div>

             <div className="col-md-9" style={{marginLeft:"-5%"}}>
              <div className="contact-about">
                <div className="form-group">
                  <label
                    className={
                      this.state.tokenvisible ? "show-element" : "hide-element"
                    } >
                    {this.state.generatedToken}
                  </label>
                </div>
              </div>
             </div>
            </div>
                        <div className="row" style={{marginTop:"5%"}}>
             <div className="col-md-2">
                <div className="form-group">
                      <button style={{marginLeft: "2%",marginTop :"5%"}}
                          className={
                            this.state.hidekeybuttonvisible
                              ? "hide-element"
                              : "link-button"
                          }
                          onClick={this.handleShowTokenVisibleViewClick}
                        >
                        View
                        </button>
                        <button style={{marginLeft: "2%",marginTop :"5%"}}
                          className={
                            this.state.hidekeybuttonvisible
                              ? "link-button"
                              : "hide-element"
                          }
                          onClick={this.handleHidetokenvisibleViewClick}
                        >
                          Hide
                        </button>
                    </div>
                  </div>
                    <div className="col-md-2">
                      <div className="contact-about">
                        <div className="form-group">
                          <button style={{marginLeft: "2%",marginTop :"5%"}}
                            className="link-button"
                            onClick={this.handleResetClick}>
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
            </div>
            */
            }

          <div><ToastContainer hideProgressBar={true} position="bottom-center" /></div>
        </div>
        <div className="col-lg-3 col-md-6 mt-4 mt-md-0"></div>
    </div>
  </>
  );
}
}
export default GenerateToken;
