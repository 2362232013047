import React, { Component } from "react";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

class User extends Component {
  render() {
    return (
      <section id="login" className="contact" style={{marginTop:"-2%"}}>
        <div className="container">
          <Login />
          <Switch>
            {/* <Route path="/login" exact component={Login}></Route> */}
            <Route path="/signup" exact component={SignUp}></Route>
            <Route path="/forgotpassword" exact component={ForgotPassword}></Route>
          </Switch>
        </div>
      </section>
    );
  }
}

export default withRouter(User);
