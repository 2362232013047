import React, { Component } from 'react'
import './styles/home.css'

export default class Home extends Component {
  
    render() {
        return (
            <section id="hero" className="d-flex align-items-center">

            <div className="container">
              <div className="row m-t-10">
                <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                  <h1 style={{"paddingBottom": 15, "color": "#4d4d4d"}} data-aos="fade-up">Your APIs Simplified</h1>
                 
                  <span style={{"fontSize": 16, "lineHeight": 1.8, "textAlign":"justify"}} data-aos="fade-up" data-aos-delay="400">
                    Our set of APIs provide an opportunity for you to explore our platform.
                      For using our APIs, you need to register to our platform. After registering,
                      you will be assigned with an access token. All our APIs are secured,
                      you need to provide access token for it. Our UI is very intuitive,
                      you will get a better understanding when you are using it.
                    </span>
                    
                </div>
                <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
                  <img src="./assets/img/hero-img.png" className="img-fluid animated" alt="" />
                </div>
              </div>
            </div>
        
          </section>
        )
    }
}
