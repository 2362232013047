import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as CgIcons from "react-icons/cg";

export default class Sidebar extends Component {
  render() {
    return (
      <div className="border-right" id="sidebar-wrapper">
        <div className="logo">
            <a href="#/loggedin/homepage">
              <img
                src="./assets/img/fintechlogo.png"
                className="img-logo"
              />
            </a>
          </div>
        <div className="sidebar-heading"></div>
        <div className="list-group list-group-flush">
          {/* <NavLink
            to="/loggedin/profile"
            className="list-group-item list-group-item-action"
          >
            <CgIcons.CgProfile />
            <span>Profile</span>
          </NavLink> */}
          <NavLink
            to="/loggedin/generatetoken"
            className="list-group-item list-group-item-action"
          >
            <CgIcons.CgPassword />
            <span>Generate Token</span>
          </NavLink>
          
          <NavLink
            to="/loggedin/fintechapi"
            className="list-group-item list-group-item-action "
          >
            <CgIcons.CgBrackets/>
            <span>Test APIs</span>
          </NavLink>
        </div>
      </div>
    );
  }
}
