import React, { Component } from "react";
import API1 from "./OtherAPIs/API1";
import API2 from "./OtherAPIs/API2";
import { BrowserRouter as Router, Route } from "react-router-dom";



export default class APIs extends Component {
  render() {
    return (
      <section id="apis" className="about">
        <div className="container">
        <API1/>
            {/* <Router>
              
             <Route path="/api2" component={API2}></Route>
          </Router> */}
        </div>
      </section>
    );
  }
}
